.banner-container {
  background-image: url(../../images/banner2.jpeg);

  background-position: center;
  background-size: cover;
  height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.banner-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 2em;
  margin-top: 3em;
  margin-bottom: 2.5em;
}

.banner-title {
  /* background: -webkit-linear-gradient(rgba(0, 174, 239, 1), #2fe628);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  background-image: linear-gradient(45deg, rgba(0, 174, 239, 1), #2fe628);
  background-size: 100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.banner-info {
  color: var(--wht);
  opacity: 0.55;
}
