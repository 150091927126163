input[type="checkbox"] {
  content: url("../../images/nofillstar.png");
  width: 20px;
  height: 20px;
}
input[type="checkbox"]:checked {
  content: url("../../images/fillstar.png");
}
.native-hidden {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  margin-right: 20px;
}
