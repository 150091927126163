select {
  height: 50px;
  text-align: center;
  border-radius: 0 10px 10px 0;
  border: none;
  background-color: rgb(33, 33, 33);
  color: white;
  margin-top: 0.4em;
  font-size: 18px;
  cursor: pointer;
}

.swap {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(27, 27, 27);
  min-height: calc(100vh - 65px);
}

.balance {
  background-color: #333;
  border: 1px solid white;
  border-radius: 10px;
  padding: 0.5em;
  margin-top: 2em;
  display: flex;
}

.balance-logo {
  margin-right: 10px;
}

/* Swap */

.swap-container {
  height: 300px;
  width: 93%;
  border: 1px solid white;
  background: linear-gradient(45deg, rgb(1, 100, 136), #298a26);
  border-radius: 10px;
  padding: 1em;
  margin-top: 2em;
}

.swap-content-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.swap-content {
  margin-top: 1em;
}

/* Input */
.input-logo {
  width: 23px;
  height: 23px;
  margin-right: 5px;
}

.input-box {
  display: flex;
  justify-content: center;
}

.input-btn {
  margin-bottom: 0 !important;
  width: 35%;
  height: 50px;
  text-align: center;
  border-radius: 0 10px 10px 0;
  border: none;
  background-color: rgb(33, 33, 33);
  color: white;
  font-size: 18px;
  cursor: pointer;
  margin-top: -1px;
  margin-left: -1px;
}

.input-field {
  height: 50px;
  width: 70%;
  border-radius: 10px 0 0 10px;
  border: none;
  text-align: left;
  background-color: rgb(33, 33, 33);
  color: white;
  padding-left: 15px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 20px;
  margin-top: -1px;
  margin-left: -1px;
}

.input-inner-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-symbol {
  font-size: 17px;
}

/* Output */
.output-logo {
  width: 23px;
  height: 23px;
  margin-right: 5px;
}

.output-box {
  display: flex;
  justify-content: center;
  margin-top: 0.5em;
  position: relative;
}

.output-btn {
  margin-bottom: 0 !important;
  width: 35%;
  height: 50px;
  text-align: center;
  border-radius: 0 10px 10px 0;
  border: 1px solid rgb(33, 33, 33);
  border-left: none;
  background-color: rgb(33, 33, 33);
  color: white;
  font-size: 18px;
  cursor: pointer;
  margin-top: -1px;
  margin-left: -1px;
}

.input-btn-test {
  margin-bottom: 0 !important;
  width: 35%;
  height: 50px;
  text-align: center;
  border-radius: 0 10px 10px 0;
  border: 1px solid rgb(33, 33, 33);
  border-left: none;
  background-color: rgb(33, 33, 33);
  color: white;
  font-size: 18px;
  cursor: pointer;
  margin-top: -1px;
  margin-left: -1px;
}

.output-field {
  height: 50px;
  width: 70%;
  border-radius: 10px 0 0 10px;
  margin-top: -1px;
  margin-left: -1px;
  border: none;
  text-align: left;
  background-color: rgb(33, 33, 33);
  color: white;
  padding-left: 15px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 20px;
}

.output-inner-box {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 5px;
  font-size: 17px;
}

.input-inner-box-test {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 5px;
  font-size: 17px;
}

/* Dropdown */

.dropdown-content {
  width: 45%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(33, 33, 33);
  z-index: 1;
  right: 0;
  top: 43px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.dropdown-content2 {
  width: 45%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(33, 33, 33);
  z-index: 1;
  right: 30px;
  top: 256px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.dropdown-list {
  width: 60%;
  font-size: 15px;
  margin-top: 0.75em !important;
  margin-bottom: 0 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(33, 33, 33);
  color: white;
  border: none;
}

.dropdown-list2 {
  width: 60%;
  font-size: 15px;
  margin-top: 0.75em !important;
  margin-bottom: 0 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(33, 33, 33);
  color: white;
  border: none;
}

/* Buttons */
.button-containers {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.approve-btn {
  width: 50%;
  height: 40px;
  text-align: center;
  border-radius: 10px;
  border: none;
  background-color: #333;
  color: white;
  cursor: pointer;
  font-size: 15px;
  margin-top: 2em;
  margin-bottom: 1em;
}

.approve-btn-approved {
  width: 50%;
  height: 40px;
  text-align: center;
  border-radius: 10px;
  border: none;
  background-color: rgba(83, 83, 83, 0.781);
  color: white;
  margin-top: 2em;
  font-size: 15px;
  pointer-events: none;
}

.approve-btn:hover {
  background-color: rgb(90, 90, 90);
  border: 1px solid whitesmoke;
  color: black;
}

.swap-btn {
  width: 50%;
  height: 40px;
  text-align: center;
  border-radius: 10px;
  border: none;
  background-color: rgba(83, 83, 83, 0.781);
  color: white;
  font-size: 15px;
  pointer-events: none;
}

.swap-btn-approved {
  width: 50%;
  height: 40px;
  text-align: center;
  border-radius: 10px;
  border: none;
  background-color: #333;
  color: white;
  cursor: pointer;
  margin-top: 0.5em;
  font-size: 15px;
}

.swap-btn-approved:hover {
  background-color: rgb(90, 90, 90);
  border: 1px solid whitesmoke;
  color: black;
}

.check-wallet {
  margin-top: 1.5em;
  background-color: #333;
  border: 1px solid white;
  border-radius: 10px;
  padding: 0.5em;
  color: white;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .balance {
    padding: 0.75em;
    font-size: 18px;
  }
  .balance-logo {
    width: 25px !important;
    height: 25px !important;
  }
  .swap-container {
    width: 80%;
  }

  .approve-btn,
  .swap-btn {
    width: 40%;
  }
  .transaction-container {
    margin-top: 2em !important;
    width: 65% !important;
  }
  .dropdown-content {
    width: 35%;
  }
  .dropdown-content2 {
    width: 35%;
  }
}

@media screen and (min-width: 900px) {
  .swap-container {
    width: 70%;
  }
}

@media screen and (min-width: 1024px) {
  .swap-container {
    width: 60%;
  }
}

@media screen and (min-width: 1440px) {
  .swap-container {
    width: 30%;
    height: 40% !important;
  }
  .swap {
    height: 50%;
  }
  .approve-btn,
  .swap-btn {
    width: 50%;
  }
  .button-containers {
    margin-top: 1em;
  }
}
