.coin-page {
  min-height: calc(100vh - 65px);
}

.coin-page {
}

.sidebar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 97%;
  margin: 0 auto;
}

.marketdata-rank {
  background: var(--main);
  color: var(--blk);
  font-size: 13px;
  border-radius: 0 0 10px 10px;
  width: 60px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: 240px;
  margin-bottom: 1em;
}

.sidebar-img {
  margin-bottom: 1em;
  width: 100px;
}

.sidebar-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 89%;
}

.sidebar-title {
  font-weight: bold;
  font-size: 30px;
  text-align: center;
}

.sidebar-symbol {
  margin-top: 5px;
  margin-left: 10px;
  text-transform: uppercase;
  font-size: 13px;
}

.sidebar-description {
  width: 90%;
  text-align: center;
  margin-top: 1em;
  margin-bottom: 2em;
}

.sidebar-description a {
  text-decoration: none;
  background-image: linear-gradient(45deg, rgba(0, 174, 239, 1), #2fe628);
  background-size: 100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.sidebar-description a:hover {
  cursor: pointer;
}

.sidebar-lower-container {
  text-align: left;
  width: 80%;
  margin-bottom: 0.5em;
}

.sidebar-price-container {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
  justify-content: space-between;
}

.sidebar-mc-container {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
  justify-content: space-between;
}

.current-price {
  background-image: linear-gradient(45deg, rgba(0, 174, 239, 1), #2fe628);
  background-size: 100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.2em;
}

.current-mc {
  background-image: linear-gradient(45deg, rgba(0, 174, 239, 1), #2fe628);
  background-size: 100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.2em;
}

.marketdata-price {
  margin-right: 25px;
}

.marketdata-mc {
  margin-right: 30px;
}

.price-change-pos {
  color: rgb(12, 180, 12);
  font-size: 13px;
}

.price-change-neg {
  color: red;
  font-size: 13px;
}

/* Tablet */

@media screen and (min-width: 768px) {
  .sidebar-lower-container {
    width: 60%;
  }
  .sidebar-description {
    width: 70%;
  }

  .marketdata-rank {
    margin-left: 570px;
  }
}

@media screen and (min-width: 900px) {
  .sidebar-description {
    width: 50%;
  }
  .sidebar-lower-container {
    width: 50%;
  }
}

@media screen and (min-width: 1024px) {
  .coin-page {
    display: flex;
    min-height: calc(100vh - 65px);
    width: 100% !important;
  }

  .hr {
    display: none !important;
  }

  .vl {
    border-left: 1px solid white;
    min-height: calc(100vh - 65px);

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .info-container {
    width: 30%;
  }

  .marketdata-rank {
    margin-left: 1500px !important;
    margin-bottom: 6em;
    width: 85px;
    height: 30px;
  }

  .chart-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: calc(100vh - 65px);
    min-width: 70%;
  }

  .sidebar-description {
    margin-top: 1.25em;
    width: 85% !important;
    font-size: 20px;
  }

  .sidebar-heading {
    width: 90%;
    display: block;
    text-align: center;
  }

  .sidebar-title {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 35px;
  }

  .sidebar-img {
    width: 140px;
    margin-bottom: 0.8em;
  }

  .sidebar-lower-container {
    width: 70%;
  }
}
/* Laptop Large */
@media screen and (min-width: 1440px) {
  .marketdata-rank {
    margin-left: 2100px !important;
  }
}
