.header {
  width: 100%;
  height: 65px;
  background-color: var(--lblk);
  border-bottom: 1px solid var(--blk);
  background-color: #111;
}

.header-container {
  height: 65px;
  width: 96%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-bottom: 1px solid var(--blk);
  background-color: #111;
}

.header-title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cs-logo {
  width: 40px;
}

.header-title a {
  text-decoration: none;
  color: var(--main);
}

a {
  text-decoration: none;
}

@media screen and (min-width: 375px) {
  .header-container {
    width: 100%;
  }
}

@media screen and (min-width: 450px) {
  .header-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 4em;
    padding-right: 3.5em;
  }
}

@media screen and (min-width: 768px) {
  .header-container {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 5em;
    padding-right: 0em;
  }
}

@media screen and (min-width: 1024px) {
  .header-container {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 6em;
    padding-right: 0em;
  }
}

@media screen and (min-width: 1440px) {
  .header-container {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 15em;
    padding-right: 0em;
  }
}
