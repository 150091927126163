.modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.581);
}

.modal-box {
  background: linear-gradient(45deg, rgb(1, 100, 136), #298a26);
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 200px;
  width: 200px;
  padding: 1rem;
  border-radius: 10px;
}

.spinner-loading {
  width: 70px !important;
  height: 40px !important;
}

.gradientText._index__spinnerText {
  display: none !important;
}

.walletcheck-msg {
  color: white;
  font-size: 1.2em;
  margin-bottom: 1em;
}

.modal-button {
  margin-top: 4.5em;
  padding-left: 0.45em;
  padding-right: 0.45em;
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  border-radius: 4px;
  border: none;
  background-color: #333;
  color: white;
  cursor: pointer;
}

.modal-button:active {
  display: hide !important;
}

.transaction-container {
  width: 80%;
  margin-top: 1em;
  text-align: center;
  border: 1px solid white;
  border-radius: 10px;
  padding: 0.6em;
  background-color: rgb(0, 0, 0);
}

.transaction-title {
  background-image: linear-gradient(45deg, rgba(0, 174, 239, 1), #2fe628);
  background-size: 100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 1em;
}

.transaction-msg a {
  text-decoration: none;
  color: white;
  cursor: pointer;
}
