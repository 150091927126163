.select-button-container {
  margin-bottom: 2em;
}

.select-button {
  border: 1px solid white;
  border-radius: 20px;
  background: var(--main);
  color: var(--blk);
  padding: 0.5em;
  font-size: 12px;
  cursor: pointer;
}

.select-button:hover {
  border: 1px solid white;
  background: var(--lblk);
  background-image: linear-gradient(45deg, rgba(0, 174, 239, 1), #2fe628);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media screen and (min-width: 1024px) {
  .select-button-container {
    margin-bottom: 0 !important;
  }
  .select-button {
    padding: 0.6em;
    font-size: 14px;
  }
}
