.chart {
  height: 250px;
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
  margin-bottom: 1em;
}

.button-container {
  display: flex;
  justify-content: space-around;
  width: 90%;
  margin: 0 auto;
}

canvas {
  height: 85% !important;
  color: #286be6 !important;
}
/* Tablet  */
@media screen and (min-width: 768px) {
  .chart {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    height: 80% !important;
    width: 85%;
  }
}

/* Laptop */

@media screen and (min-width: 1024px) {
  .chart {
    height: 95%;
    width: 95%;
    margin-top: -1.5em !important;
  }
  canvas {
    height: 67% !important;
    width: 98% !important;
  }

  .button-container {
    margin-top: -4em;
  }
}

/* Laptop Large */

@media screen and (min-width: 1440px) {
  canvas {
    height: 85% !important;
  }

  .button-container {
    margin-top: -1em;
  }

  .chart {
    margin-top: 1em !important;
  }
}
